import { Box, Typography } from "@mui/material";
import { BarChart, LineChart, PieChart } from "@mui/x-charts";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import * as S from "../../constants/StringConstants";

interface MetricsData {
  date: string;
  blocks: number;
  bounceDrops: number;
  bounces: number;
  clicks: number;
  deferred: number;
  delivered: number;
  invalidEmails: number;
  opens: number;
  processed: number;
  requests: number;
  spamReportDrops: number;
  spamReports: number;
  uniqueClicks: number;
  uniqueOpens: number;
  unsubscribeDrops: number;
  unsubscribes: number;
  noOfLandingPageSubmissions: number;
}

// Fetch monthly aggregated data
const fetchMonthlyEmailStats = async () => {
  try {
    const res = await axios.get("/ioapp/emailStats/monthly");
    return res.data;
  } catch (error) {}
};

// Fetch data for a custom date range
const fetchEmailStatsByRange = async (startDate: string, endDate: string) => {
  try {
    const res = await axios.get(`/ioapp/emailStats/byRange?startDate=${startDate}&endDate=${endDate}`);
    return res.data;
  } catch (error) {}
};

const Dashboard: React.FC = () => {
  const [data, setData] = useState<MetricsData[]>([]);
  const [startDate, setStartDate] = useState<Moment | null>(moment("2024-08-01"));
  const [endDate, setEndDate] = useState<Moment | null>(moment("2024-10-01"));
  const [_, setIsCustomRange] = useState(false);

  // Fetch monthly data on initial load
  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const fetchedData = await fetchMonthlyEmailStats();
        setData(fetchedData);
      } catch (error) {}
    };

    fetchMonthlyData();
  }, []);

  // Fetch data based on selected date range
  const fetchCustomRangeData = async () => {
    if (startDate && endDate) {
      setIsCustomRange(true); // Marking to indicate using custom range data
      try {
        const fetchedData = await fetchEmailStatsByRange(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
        setData(fetchedData);
      } catch (error) {}
    }
  };

  return (
    <Box sx={{ padding: "2rem", maxWidth: "1100px", margin: "0 auto" }}>
      <h1 className="heading1 grey--light">{S.DASH_PAGE_TITLE}</h1>

      {/* Date Range Selection */}
      <Box sx={{ display: "flex", gap: 2, mb: 10, mt: 10, justifyContent: "center" }}>
        <DatePicker label={S.DASH_START_DATE} value={startDate} onChange={newDate => setStartDate(newDate)} />
        <DatePicker label={S.DASH_END_DATE} value={endDate} onChange={newDate => setEndDate(newDate)} />
        <button type="button" className="button__orange" onClick={fetchCustomRangeData}>
          {S.DASH_APPLY}
        </button>

        <button
          className="form-modal__cancel-button"
          onClick={async () => {
            const monthlyData = await fetchMonthlyEmailStats();
            setData(monthlyData);
            setIsCustomRange(false); // Reset to monthly data
          }}>
          {S.DASH_RESET}
        </button>
      </Box>

      {/* Delivered, Deferred, and Blocks */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h6">{S.DASH_DELIVERED_VS_DEFERRED_VS_BLOCKS}</Typography>
        <BarChart
          width={1000}
          height={400}
          series={[
            { data: data.map(d => d.delivered), label: S.DASH_DELIVERED, color: "#4CAF50" },
            { data: data.map(d => d.deferred), label: S.DASH_DEFERRED, color: "#FF9800" },
            { data: data.map(d => d.blocks), label: S.DASH_BLOCKS, color: "#F44336" },
          ]}
          xAxis={[{ scaleType: "band", data: data.map(d => d.date) }]}
        />
      </Box>

      {/* Engagement Breakdown */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h6">{S.DASH_ENGAGEMENT_BREAKDOWN}</Typography>
        <PieChart
          width={1000}
          height={400}
          series={[
            {
              data: [
                { value: data.reduce((acc, item) => acc + item.opens, 0), label: S.DASH_OPENS },
                { value: data.reduce((acc, item) => acc + item.clicks, 0), label: S.DASH_CLICKS },
                { value: data.reduce((acc, item) => acc + item.unsubscribes, 0), label: S.DASH_UNSUBSCRIBES },
                { value: data.reduce((acc, item) => acc + item.spamReports, 0), label: S.DASH_SPAM_REPORTS },
              ],
            },
          ]}
        />
      </Box>

      {/* Monthly or Custom Range Trends in Opens, Clicks, Unsubscribes */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h6">{S.DASH_MONTHLY_ENGAGEMENT_TRENDS}</Typography>
        <LineChart
          width={1000}
          height={400}
          series={[
            { data: data.map(d => d.opens), label: S.DASH_OPENS, color: "#3f51b5" },
            { data: data.map(d => d.clicks), label: S.DASH_CLICKS, color: "#9C27B0" },
            { data: data.map(d => d.unsubscribes), label: S.DASH_UNSUBSCRIBES, color: "#FFC107" },
          ]}
          xAxis={[{ scaleType: "band", data: data.map(d => d.date) }]}
        />
      </Box>

      {/* Bounces and Deferred Emails */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h6">{S.DASH_BOUNCES_AND_DEFERRED_EMAILS}</Typography>
        <LineChart
          width={1000}
          height={400}
          series={[
            { data: data.map(d => d.bounces), label: S.DASH_BOUNCES, color: "#FF5722" },
            { data: data.map(d => d.deferred), label: S.DASH_DEFERRED, color: "#009688" },
          ]}
          xAxis={[{ scaleType: "band", data: data.map(d => d.date) }]}
        />
      </Box>

      <Box sx={{ mb: 5 }}>
        <Typography variant="h6">{S.DASH_LANDING_PAGE_SUBMISSIONS}</Typography>
        <BarChart
          width={1000}
          height={400}
          series={[
            {
              data: data.map(d => d.noOfLandingPageSubmissions),
              label: S.DASH_LANDING_PAGE_SUBMISSIONS,
              color: "#FF5722",
            },
          ]}
          xAxis={[{ scaleType: "band", data: data.map(d => d.date) }]}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
