import { FormApi } from "final-form";
import arrayMutators from "final-form-arrays";
import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import Toast from "src/components/Toast/Toast";
import UploadDocumentModal from "src/components/UploadDocumentModal/UploadDocumentModal";
import * as S from "src/constants/StringConstants";
import { IIOCampaignCreationData, IIOCampaignRequestPayload } from "src/store/models/campaign.model";
import { IIOTemplateStep } from "src/store/models/template.model";
import { IRootState } from "src/store/reducers";
import { createCampaign, hideErrorToastCampaign } from "src/store/reducers/campaign";
import { generateMessageQueue } from "./api";
import "./CampaignBuilder.scss";
import CampaignStepper from "./CampaignStepper";
import { Contacts, Import, Journey, Preview } from "./CampaignSteps";
import { CARRIER_OPTIONS, COLUMNS } from "./Constants/columns";
import { CampaignSteps, STEPS as steps } from "./Constants/steps";
import { campaignBuilderValidations } from "./Constants/validations";
import { generateCampaignPayload } from "./generateCampaignPayload";
import { bytesToBase64 } from "./utils";

export interface Props extends StateProps, DispatchProps, RouteComponentProps {
  campaignId?: string;
  initialValue: any;
  onNameChange: (name: string) => void;
  onReplaceFile: (files: File[], values: Record<string, any>) => void;
  onDeleteCampaign: () => void;
}

const CampaignBuilder: React.FC<Props> = props => {
  const {
    initialValue,
    hideErrorToastCampaign,
    campaign: { errorMessage },
    campaignId,
  } = props;

  const { saveAsDraft } = initialValue;
  const activeCampaign = campaignId && !saveAsDraft;

  const [activeStep, setActiveStep] = useState<CampaignSteps>(
    activeCampaign ? CampaignSteps.JOURNEY : CampaignSteps.IMPORT,
  );
  const [isFormSubmissionSuccess, setIsFormSubmissionSuccess] = useState(false);
  const [replaceModalOpen, setReplaceModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const nextStep = () => {
    setActiveStep(prev => {
      if (prev === CampaignSteps.PREVIEW) {
        return prev;
      }
      return prev + 1;
    });
  };

  const prevStep = () => {
    setActiveStep(prev => {
      if (prev === CampaignSteps.IMPORT) {
        return prev;
      }
      return prev - 1;
    });
  };

  const replaceFile = () => {
    setReplaceModalOpen(true);
  };

  const deleteCampaign = () => {
    props.onDeleteCampaign();
  };

  const onSubmit = async (data: IIOCampaignCreationData, { change }: FormApi) => {
    const isLastPage = activeStep === CampaignSteps.PREVIEW;

    const { saveAsDraft } = data;

    if (activeStep === CampaignSteps.IMPORT) {
      const carrierName =
        data.options.carrierId > 0
          ? CARRIER_OPTIONS.find(carrier => {
              return carrier.value == data.options.carrierId;
            }).key
          : null;

      // update title
      props.onNameChange([data.fileName, carrierName].filter(x => !!x).join(" - "));

      // update rowData
      change(
        "rowData",
        data.rows
          .filter(row => row.some(x => !!x))
          .map((row, index) => {
            const obj: any = { carrierName };
            COLUMNS.forEach((col, index) => {
              if (data.mapTo[index].value === -1) return;
              const v = row[data.mapTo[index].value];
              obj[col.key] = col.parser ? col.parser(v) : v;
            });
            obj.id = index;
            obj.expirationDate = data.options.expirationDate;
            obj.allData = bytesToBase64(new TextEncoder().encode(JSON.stringify(obj)));
            return obj;
          }),
      );
    }

    if (activeStep === CampaignSteps.JOURNEY) {
      let firstMessageDate = moment.tz(
        `${data.options.firstDeliveryDate} ${data.options.time}`,
        "YYYY-MM-DD HH:mm a",
        "America/Los_Angeles",
      );

      change(
        "templateSteps",
        data.templateSteps.map((step: IIOTemplateStep) => {
          firstMessageDate.add(step.intervalDays || 0, "days");
          return {
            ...step,
            actionDatetime: firstMessageDate.format(),
          };
        }),
      );
    }

    if (isLastPage || saveAsDraft) {
      const payload: IIOCampaignRequestPayload = generateCampaignPayload(data, props.user.userID);
      try {
        const result: any = await props.createCampaign(payload);
        const id = get(result, "action.payload.data") as number;
        if (!saveAsDraft) {
          await generateMessageQueue(id);
        }
        setIsFormSubmissionSuccess(true);
        props.history.push("/campaigns/all");
      } catch (error) {
        hideErrorToastCampaign();
      }
    } else {
      nextStep();
    }
  };

  return (
    <>
      <Toast
        message={errorMessage}
        open={errorMessage !== "" && errorMessage !== null && errorMessage !== undefined}
        onClose={() => {
          hideErrorToastCampaign();
        }}
      />
      <Toast message={S.UPLOADING(uploadPercentage)} onClose={() => {}} open={loading} />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValue}
        mutators={{
          ...arrayMutators,
        }}
        validate={(values: IIOCampaignCreationData) => campaignBuilderValidations(values, activeStep, activeCampaign)}>
        {({ values, handleSubmit, submitting: isSubmitting, errors, form }) => (
          <form className="campaign-details" onSubmit={handleSubmit}>
            <Toast message={S.CS_CAMPAIGN_SUBMITTED} onClose={() => {}} open={isSubmitting} />
            <Toast
              message={S.CS_CAMPAIGN_SUBMITTED_SUCCESS}
              onClose={() => {
                setIsFormSubmissionSuccess(false);
              }}
              open={isFormSubmissionSuccess}
            />
            <UploadDocumentModal
              open={replaceModalOpen}
              handleFiles={async files => {
                if (files?.length >= 1) {
                  setActiveStep(0);
                  await props.onReplaceFile(files, values);
                  setReplaceModalOpen(false);
                }
              }}
              onCancel={() => {
                setReplaceModalOpen(false);
              }}
            />

            <div className="campaign-details__builder">
              <div className="campaign-details__container">
                <CampaignStepper activeStep={activeStep} onClick={(_, __) => {}} steps={steps} />

                <div className="campaign-details__builder__form">
                  {activeStep === CampaignSteps.IMPORT && <Import values={values} />}
                  {activeStep === CampaignSteps.CONTACTS && <Contacts values={values} />}
                  {activeStep === CampaignSteps.JOURNEY && (
                    <Journey errors={errors} values={values} isActiveCampaign={activeCampaign} />
                  )}
                  {activeStep === CampaignSteps.PREVIEW && <Preview values={values} userId={props.user.userID} />}
                </div>

                <div className="iobuttons iobuttons__wrap">
                  <div className="iobuttons__left">
                    <button
                      className="button__orange"
                      type="button"
                      disabled={isSubmitting}
                      onClick={e => {
                        replaceFile();
                      }}>
                      {S.CAMP_REPLACE_FILE}
                    </button>
                    {campaignId ? (
                      <button
                        className="button__grey"
                        type="button"
                        disabled={isSubmitting}
                        onClick={e => {
                          deleteCampaign();
                        }}>
                        {S.CAMP_DELETE_CAMPAIGN}
                      </button>
                    ) : null}
                  </div>
                  <div>
                    <div className="iobuttons__right">
                      <button
                        className="button__grey"
                        type="submit"
                        onClick={() => {
                          form.change("saveAsDraft", true);
                        }}
                        disabled={isSubmitting}>
                        {isSubmitting ? <Spinner color="light" /> : S.CAMP_SAVE_DRAFT_BUTTON}
                      </button>

                      {activeStep > CampaignSteps.IMPORT && (
                        <button className="button__orange" type="button" disabled={isSubmitting} onClick={prevStep}>
                          {isSubmitting ? <Spinner color="light" /> : S.CAMP_PREV_BUTTON}
                        </button>
                      )}
                      <button
                        className="button__orange"
                        type="submit"
                        onClick={() => {
                          form.change("saveAsDraft", false);
                        }}
                        disabled={isSubmitting}>
                        {isSubmitting ? (
                          <Spinner color="light" />
                        ) : activeStep === CampaignSteps.PREVIEW ? (
                          S.CAMP_SAVE_BUTTON
                        ) : (
                          S.CAMP_NEXT_BUTTON
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

const mapStateToProps = ({ campaign, auth }: IRootState) => ({ campaign, user: auth.user });
const mapDispatchToProps = {
  createCampaign,
  hideErrorToastCampaign,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignBuilder));
