import { FormApi, FORM_ERROR } from "final-form";
import createDecorator from "final-form-calculate";
import { get, set } from "lodash";
import React, { Fragment } from "react";
import { Field, Form as FinalForm, FormSpy } from "react-final-form";
import { connect } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";

import { defaultBroker, IBroker } from "../../store/models/broker.model";
import { IRootState } from "../../store/reducers";
import {
  fetchBrokerByPrimaryEmail,
  fetchBrokerOptions,
  fetchCompanyAffiliate,
} from "../../store/reducers/brokerBuilder";
import {
  createBroker,
  fetchBroker,
  hideErrorToastBroker,
  updateBroker,
} from "../../store/reducers/brokers";
import {
  deleteBrokerLogo,
  saveBrokerLogo,
  uploadBrokerLogo,
} from "../../store/reducers/brokersImages";

import { Button, Icon } from "@mui/material";
import formatString from "format-string-by-pattern";
import { OnChange } from "react-final-form-listeners";
import { RouteComponentProps } from "react-router";
import NumberFormatField from "../../components/Form/NumberFormatField/NumberFormatField";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import TextField from "../../components/Form/TextField/TextField";
import ToggleField from "../../components/Form/ToggleField/ToggleField";
import TypeaheadField, {
  ITypeaheadOption,
  keyValueToDropdownOption,
} from "../../components/Form/TypeaheadField/TypeaheadField";
import Toast from "../../components/Toast/Toast";
import { BASE_API_URL } from "../../constants/EnvConstants";
import * as S from "../../constants/StringConstants";
import { formatUSD } from "../../util/utils.currency";
import {
  addDefaultOption,
  isEmptyOrDefault,
  undefinedIfDefault,
} from "../../util/utils.defaultValues";
import * as P from "../../util/utils.validators";
import "./BrokerBuilder.scss";
import BrokerQuotesWidjet from "./BrokerQuotesWidjet";
import BrokerSecureDocumentsWidjet from "./BrokerSecureDocumentsWidjet";
import ExistentBrokerNav from "./ExistentBrokerNav";

interface IBrokerBuilderProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

class BrokerBuilder extends React.Component<IBrokerBuilderProps> {
  validate = (values: any) => {
    const errors = {};

    // doing this to ensure at least one is chosen (no default specified out of true/false)
    if (values.isBusinessOwner < 0) {
      errors["isBusinessOwner"] = S.FORM_FIELD_REQUIRED;
    }

    if (P.isEmpty(values.firstName)) {
      errors["firstName"] = S.FORM_FIELD_REQUIRED;
    }

    if (P.isEmpty(values.lastName)) {
      errors["lastName"] = S.FORM_FIELD_REQUIRED;
    }

    if (!values.primaryEmail) {
      errors["primaryEmail"] = S.FORM_FIELD_REQUIRED;
    }

    const howDidYouHearAboutUsId = get(values, "hearAboutTypeId");
    if (!howDidYouHearAboutUsId || howDidYouHearAboutUsId < 0) {
      set(errors, "hearAboutTypeId", S.FORM_FIELD_REQUIRED);
    }

    const externalID = get(values, "externalID");
    if (externalID && !P.EXTERNAL_ID_REGEX.test(externalID)) {
      set(errors, "externalID", S.FORM_FIELD_INVALID);
    }

    const phoneFields = ["officePhone", "cellPhone", "faxPhone"];
    phoneFields.map(fieldName => {
      const phoneNumber = get(values, fieldName);
      const pattern = P.PHONE_NUMBER_REGEX;
      if (phoneNumber && !pattern.test(phoneNumber)) {
        set(errors, fieldName, S.FORM_FIELD_INVALID);
      }
    });

    const zipCode = get(values, "address.zip");
    const zipPattern = P.ZIP_CODE_REGEX;
    if (zipCode && !zipPattern.test(zipCode)) {
      set(errors, "address.zip", S.FORM_FIELD_INVALID);
    }

    const branchTypeID = get(values, "branchTypeID");
    if (isEmptyOrDefault(branchTypeID)) {
      set(errors, "branchTypeID", S.FORM_FIELD_REQUIRED);
    }

    return errors;
  };

  handleSubmit = async (values: any) => {
    const broker = this.formValuesToBroker(values);

    try {
      if (this.brokerID) {
        this.fillBrokerEmptyValues(broker);
        await this.props.updateBroker(broker);
      } else {
        await this.props.createBroker(broker);
      }
    } catch (err) {
      return { [FORM_ERROR]: "Error Submitting" };
    }

    // TODO: Get this path from a library/directory
    this.props.history.push("/brokers/all");
  };

  fillBrokerEmptyValues(broker) {
    if (typeof get(broker, "companyID") !== "number") {
      set(broker, "companyID", 0);
    }
    if (typeof get(broker, "affiliateID") !== "number") {
      set(broker, "affiliateID", undefined);
    }
  }

  componentDidMount() {
    this.props.fetchBrokerOptions();

    // check match for id param
    const id = this.brokerID;
    if (id) {
      this.props.fetchBroker(id.toString());
    }

    this.props.history.listen((location, action) => {
      this.formStateTemp = undefined;
    });
  }

  formValuesToBroker = (values: any) => {
    const primaryPhone = get(values, "primaryPhone");
    const cellPhone = get(values, "cellPhone");
    const isSMSTextEnabled = get(values, "isSMSTextEnabled");

    const brokerPhones = [
      {
        phoneNumber: get(values, "officePhone"),
        isPrimary: primaryPhone === S.BRO_PHONE_TYPE_OFFICE,
        phoneType: S.BRO_PHONE_TYPE_OFFICE,
      },
      {
        phoneNumber: cellPhone,
        isPrimary: primaryPhone === S.BRO_PHONE_TYPE_CELL,
        phoneType: S.BRO_PHONE_TYPE_CELL,
      },
      {
        phoneNumber: get(values, "faxPhone"),
        phoneType: S.BRO_PHONE_TYPE_FAX,
      },
    ].filter(x => x && x.phoneNumber);

    const brokerAdditionalEmails = [
      {
        emailAddress: get(values, "primaryEmail"),
        emailType: S.BRO_EMAIL_TYPE_PRIMARY,
      },
      {
        emailAddress: get(values, "alternateEmail"),
        emailType: S.BRO_EMAIL_TYPE_ALT,
      },
      {
        emailAddress: get(values, "ccEmail"),
        emailType: S.BRO_EMAIL_TYPE_CC,
      },
      {
        emailAddress: get(values, "requestorEmail"),
        emailType: S.BRO_EMAIL_TYPE_REQUESTER,
      },
    ].filter(x => x && x.emailAddress);

    let affiliateIDs = get(values, "affiliateID");
    let affiliateID;

    if (affiliateIDs && affiliateIDs.length && affiliateIDs[0].value) {
      affiliateID = affiliateIDs[0].value;
    } else if (!isNaN(affiliateIDs)) {
      affiliateID = affiliateIDs;
    } else {
      affiliateID = undefined;
    }

    let companyIDs = undefinedIfDefault(get(values, "companyID"));
    //  Default behavior for an empty new form is to have it as undefined
    let companyID = undefined;

    if (Array.isArray(companyIDs) && companyIDs.length > 0) {
      // form was pre-populated with a list of options
      companyID = companyIDs[0].value;
    } else if (Number.isInteger(companyIDs)) {
      // a single item has been selected
      companyID = companyIDs;
    }

    if (values.address) {
      values.address.stateID = undefinedIfDefault(values.address.stateID);
    }

    return {
      brokerID: get(values, "brokerID"),
      firstName: get(values, "firstName"),
      lastName: get(values, "lastName"),
      preferredName: get(values, "preferredName"),
      companyID: companyID,
      affiliateID: undefinedIfDefault(affiliateID),
      externalID: undefinedIfDefault(get(values, "externalID")),
      branchTypeID: undefinedIfDefault(get(values, "branchTypeID")),
      rbManagerID: undefinedIfDefault(get(values, "rbManagerID")),
      hearAboutTypeId: undefinedIfDefault(get(values, "hearAboutTypeId")),
      isBusinessOwner: get(values, "isBusinessOwner") === "1" ? true : false,
      brokerPhones,
      isSMSTextEnabled,
      brokerAdditionalEmails,
      address: get(values, "address"),
      notes: get(values, "notes"),
    };
  };

  brokerToFormValues = (broker: IBroker, affiliates: ITypeaheadOption[]) => {
    const brokerPhones = get(broker, "brokerPhones") || [];
    const brokerAdditionalEmails = get(broker, "brokerAdditionalEmails") || [];
    let officePhoneItem = brokerPhones.find(
      x => x.phoneType === "Office" /* 1 S.BRO_PHONE_TYPE_OFFICE*/,
    );

    const affiliate = affiliates.find(a => a.value === get(broker, "affiliateID"));

    let officePhone = get(officePhoneItem, "phoneNumber");
    if (!officePhone) {
      officePhone = get(broker, "officePhone");
    }

    let cellPhone = get(
      brokerPhones.find(x => x.phoneType === "Cell" /* 2 S.BRO_PHONE_TYPE_CELL*/),
      "phoneNumber",
    );
    if (!cellPhone) {
      cellPhone = get(broker, "cellPhone");
    }

    let faxPhone = get(
      brokerPhones.find(x => x.phoneType === "Fax" /* 3 S.BRO_PHONE_TYPE_FAX*/),
      "phoneNumber",
    );
    if (!faxPhone) {
      faxPhone = get(broker, "faxPhone");
    }

    let primaryEmail = get(
      brokerAdditionalEmails.find(x => x.emailType === "Primary" /* 1 S.BRO_EMAIL_TYPE_PRIMARY*/),
      "emailAddress",
    );
    if (!primaryEmail) {
      primaryEmail = get(broker, "primaryEmail");
    }

    let alternateEmail = get(
      brokerAdditionalEmails.find(x => x.emailType === "Alternate" /* 2 S.BRO_EMAIL_TYPE_ALT*/),
      "emailAddress",
    );
    if (!alternateEmail) {
      alternateEmail = get(broker, "alternateEmail");
    }

    let ccEmail = get(
      brokerAdditionalEmails.find(x => x.emailType === "CC" /* 3 S.BRO_EMAIL_TYPE_CC*/),
      "emailAddress",
    );
    if (!ccEmail) {
      ccEmail = get(broker, "ccEmail");
    }

    let requestorEmail = get(
      brokerAdditionalEmails.find(
        x => x.emailType === "Requester" /* 4 S.BRO_EMAIL_TYPE_REQUESTER*/,
      ),
      "emailAddress",
    );
    if (!requestorEmail) {
      requestorEmail = get(broker, "requestorEmail");
    }

    return {
      brokerID: get(broker, "brokerID"),
      firstName: get(broker, "firstName"),
      lastName: get(broker, "lastName"),
      preferredName: get(broker, "preferredName"),
      companyFreeTypeName: get(broker, "companyFreeTypeName"),
      affiliateFreeTypeName: get(broker, "affiliateFreeTypeName"),
      companyID: get(broker, "companyID"),
      affiliateID: affiliate ? affiliate.value : undefined,
      externalID: get(broker, "externalID"),
      isSMSTextEnabled: get(broker, "isSMSTextEnabled"),
      branchTypeID: get(broker, "branchTypeID"),
      rbManagerID: get(broker, "rbManagerID"),
      hearAboutTypeId: get(broker, "hearAboutTypeId"),
      isBusinessOwner: get(broker, "isBusinessOwner") ? 1 : 0,
      address: get(broker, "address"),
      notes: get(broker, "notes"),
      officePhone: officePhone,
      cellPhone: cellPhone,
      faxPhone: faxPhone,
      primaryPhone:
        officePhoneItem && officePhoneItem.isPrimary
          ? S.BRO_PHONE_TYPE_OFFICE
          : S.BRO_PHONE_TYPE_CELL,
      primaryEmail: primaryEmail,
      alternateEmail: alternateEmail,
      ccEmail: ccEmail,
      requestorEmail: requestorEmail,
    };
  };

  getPrimaryPhoneLabel(form: FormApi, key: string) {
    const field = form.getFieldState("primaryPhone");
    if (!field) {
      return "";
    }

    return field.value === key ? S.BRO_PRIMARY_LABEL : "";
  }

  defaultBroker = undefined;
  get broker() {
    if (this.brokerID) {
      return this.props.brokers.map[this.props.match.params.id];
    } else {
      if (!this.defaultBroker) {
        this.defaultBroker = defaultBroker;
      }

      return this.defaultBroker;
    }
  }

  get brokerID() {
    const id = parseInt(this.props.match.params.id, 10);

    return id && !isNaN(id) ? id : null;
  }

  formStateTemp = undefined;
  formStateHandler = createDecorator({
    field: /./,
    updates: (value, name, allValues, previousValues) => {
      this.formStateTemp = allValues;
      return {};
    },
  });

  onBrokerFileSelectionChange = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.accept.includes("image") ? "images" : "videos";

    var formData = new FormData();
    formData.append("image", target.files[0]);

    fileReader.onload = e => {
      this.props.saveBrokerLogo(this.broker, formData, e.target.result);
      this.props.uploadBrokerLogo(this.broker, formData);
    };
    fileReader.readAsDataURL(target.files[0]);
  };

  onBrokerFileDelete = ({ target }) => {
    this.broker.brokerLogo = undefined;
    this.props.deleteBrokerLogo(this.broker);
  };

  render() {
    const { existentBrokerIdByEmail } = this.props;
    const { dropdownOptions, brokerImages, companyAffiliate, brokers, hideErrorToastBroker } =
      this.props;
    const officePhoneMask = { name: "phone-1", parse: "(999) 999-9999.999999" };
    const phoneMask = { name: "phone-1", parse: "(999) 999-9999" };

    var logoBrokerUrl = undefined;
    var image = brokerImages.images.find(x => x.brokerID == this.brokerID);
    if (image) {
      logoBrokerUrl = image.imagePreview;
    } else {
      if (this.broker && this.broker.brokerLogo) {
        logoBrokerUrl = BASE_API_URL + "brokers/" + this.broker.brokerLogo + "/brokerLogo";
      }
    }

    let totalPremium = 0;

    const brokerProductionNodes = (
      this.broker && this.broker.brokerProduction && this.broker.brokerProduction.length
        ? this.broker.brokerProduction
        : defaultBroker.brokerProduction
    ).reduce((memo, item, index) => {
      totalPremium += item.premiumTotal || 0;

      return memo.concat([
        <Row className="card-separator" key={`separator_${index}`} />,
        <Row className="card-row card-values" key={`prod_${index}`}>
          <Col sm="8">{item.carrierType || S.BRO_CARD_NOTHING_YET}</Col>
          <Col sm="4" className="left">
            {item.premiumTotal ? `${formatUSD(item.premiumTotal)}` : "$0.00"}
          </Col>
        </Row>,
      ]);
    }, []);

    const defaultValues = {
      affiliateID: undefined,
      branchTypeID: undefined,
      companyID: undefined,
      externalID: 0,
      primaryPhone: S.BRO_PHONE_TYPE_OFFICE,
      ...this.formStateTemp,
    };

    const affiliateList = (get(dropdownOptions, "affiliateList") || []).map(
      keyValueToDropdownOption,
    );

    const branchList = addDefaultOption(
      S.DD_BUILDER_LABEL_BRANCH,
      get(dropdownOptions, "branchList", []),
    );

    const rbmList = addDefaultOption("", get(dropdownOptions, "rbmList", []));
    const stateList = addDefaultOption("", get(dropdownOptions, "selectStateList", []));
    const businessOwnerList = [
      {
        key: "",
        value: -1,
      },
      {
        key: "Yes",
        value: 1,
      },
      {
        key: "No",
        value: 0,
      },
    ];

    const initialValues = this.broker
      ? this.brokerToFormValues(this.broker, affiliateList)
      : defaultValues;
    const hearAboutUsFullList = addDefaultOption(
      S.DD_BUILDER_LABEL_HOWDIDYOUHEARABOUTUS,
      get(dropdownOptions, "hearAboutUsList", []),
    );
    return (
      <div>
        <Toast message={S.LOADING} onClose={() => {}} open={this.props.loading} />
        <Toast
          message={brokers.errorMessage}
          onClose={() => {
            hideErrorToastBroker();
          }}
          open={brokers.error}
        />
        <FinalForm
          validate={this.validate}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          decorators={[this.formStateHandler]}
          render={({ handleSubmit, submitting, invalid, values, form }) => {
            // If viewing an existing broker with an affiliate always select that affiliate.
            // If there is no existing broker affiliate use the affiliate of the selected company.
            let affiliate = undefined;
            const brokerAffiliateID = get(values, "affiliateID");
            if (brokerAffiliateID) {
              affiliate = affiliateList.find(a => a.value === brokerAffiliateID);
              defaultValues.affiliateID = affiliate ? affiliate.value : 0;
            } else if (companyAffiliate) {
              affiliate = affiliateList.find(a => a.value === get(companyAffiliate, "affiliateID"));
              defaultValues.affiliateID = affiliate ? affiliate.value : 0;
            }

            var unfilteredCompanyList = get(dropdownOptions, "companyList", []);
            if (affiliate) {
              unfilteredCompanyList = unfilteredCompanyList.filter(
                c => c.affiliateId === affiliate.value,
              );
            }

            const companyList = addDefaultOption(
              S.DD_BUILDER_LABEL_COMPANY,
              unfilteredCompanyList.sort(function (a, b) {
                return a.key.localeCompare(b.key);
              }),
            ).map(keyValueToDropdownOption);

            let selectedCompany = undefined;
            if (values.companyID) {
              selectedCompany = companyList.find(c => c.value === values.companyID);
            }
            return (
              <div className="broker-builder">
                <h1 className="heading1 grey--light">{S.BRO_NEW_TITLE}</h1>
                <form onSubmit={handleSubmit}>
                  <div className="broker-builder__form">
                    <section className="broker-builder__wrap">
                      <Container className="broker-builder__container">
                        <Row>
                          <Col>
                            <h1 className="heading3">{S.BRO_INFO_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <TextField
                              name="firstName"
                              key="broker.firstName-key"
                              label={S.BRO_FIRST_NAME_LABEL}
                            />
                          </Col>
                          <Col sm="3">
                            <TextField
                              name="lastName"
                              key="broker.lastName-key"
                              label={S.BRO_LAST_NAME_LABEL}
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              name="preferredName"
                              key="broker.preferredName-key"
                              label={S.BRO_PREF_NAME_LABEL}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <TypeaheadField
                              className="overview__broker-details-input"
                              name="affiliateID"
                              key="broker.affiliateID-key"
                              label={S.BRO_AFFILIATE_LABEL}
                              options={affiliateList}
                              selected={affiliate}
                            />
                            <OnChange name="affiliateID">
                              {value => {
                                if (value && value.length === 1) {
                                  const affiliateID = get(value, "0.value");
                                  let existentCompanyID = get(values, "companyID");
                                  var metaAfiliateData = get(
                                    dropdownOptions,
                                    "affiliateList",
                                    [],
                                  ).find(x => x.value === affiliateID);

                                  form.change("affiliateID", affiliateID);
                                  var metaCompanyData = get(
                                    dropdownOptions,
                                    "companyList",
                                    [],
                                  ).find(
                                    x =>
                                      x.value === existentCompanyID &&
                                      x.affiliateId === affiliateID,
                                  );
                                  if (!metaCompanyData) {
                                    form.change("companyID", null);
                                  }

                                  if (!existentCompanyID && metaAfiliateData) {
                                    if (metaAfiliateData.branchTypeID) {
                                      form.change("branchTypeID", metaAfiliateData.branchTypeID);
                                    }
                                    if (metaAfiliateData.rbManagerID) {
                                      form.change("rbManagerID", metaAfiliateData.rbManagerID);
                                    }

                                    if (!existentCompanyID) {
                                      existentCompanyID = 0;
                                    }
                                    this.props.fetchCompanyAffiliate(existentCompanyID);
                                  }
                                }
                              }}
                            </OnChange>
                          </Col>
                          <Col sm="5">
                            <TypeaheadField
                              className="overview__broker-details-input"
                              name="companyID"
                              label={S.BRO_COMPANY_LABEL}
                              key="broker.companyID-key"
                              options={companyList}
                              selected={selectedCompany}
                            />
                            <OnChange name="companyID">
                              {value => {
                                const companyID = get(value, "0.value");

                                if (companyID) {
                                  var metaCompanyData = get(
                                    dropdownOptions,
                                    "companyList",
                                    [],
                                  ).find(x => x.value === companyID);
                                  try {
                                    this.props.fetchCompanyAffiliate(companyID);
                                    form.change("affiliateID", metaCompanyData.affiliateId);
                                    form.change("companyID", companyID);
                                    form.change("branchTypeID", metaCompanyData.branchTypeID);
                                    form.change("rbManagerID", metaCompanyData.rbManagerID);
                                  } catch (e) {
                                    console.log("exception:", e);
                                  }
                                }
                              }}
                            </OnChange>
                          </Col>
                          <Col sm="2">
                            <TextField
                              name="brokerID"
                              key="broker.brokerID-key"
                              label={S.BRO_BROKER_ID_LABEL}
                              disabled={true}
                            />
                          </Col>
                          <Col sm="2">
                            <NumberFormatField
                              name="externalID"
                              key="broker.externalID-key"
                              label={S.BRO_EXTERNAL_ID_LABEL}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <TextField
                              name="affiliateFreeTypeName"
                              key="broker.affiliateFreeTypeName-key"
                              label={S.BRO_AFFILIATE_PUBLIC_LABEL}
                              disabled="disabled"
                            />
                          </Col>
                          <Col sm="5">
                            <TextField
                              name="companyFreeTypeName"
                              key="broker.companyFreeTypeName-key"
                              label={S.BRO_COMPANY_PUBLIC_LABEL}
                              disabled="disabled"
                            />
                          </Col>
                          <Col sm="4">
                            <SingleSelectField
                              name="hearAboutTypeId"
                              key="broker.hearAboutTypeId-key"
                              label={S.BRO_HOW_DID_YOU_HEAR_ABOUT_US_LABEL}
                              options={hearAboutUsFullList}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <SingleSelectField
                              name="branchTypeID"
                              key="broker.branchTypeID-key"
                              label={S.BRO_BRANCH_LABEL}
                              options={branchList}
                            />
                          </Col>
                          <Col sm="3">
                            <SingleSelectField
                              name="rbManagerID"
                              key="broker.rbManagerID-key"
                              label={S.BRO_RBM_LABEL}
                              options={rbmList}
                            />
                          </Col>
                          <Col sm="3">
                            <SingleSelectField
                              name="isBusinessOwner"
                              key="broker.branchTypeID-key"
                              label={S.BRO_BUSINESS_OWNER_LABEL}
                              options={businessOwnerList}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1 className="heading3">{S.BRO_PHONE_TITLE}</h1>
                          </Col>
                        </Row>
                        <FormSpy
                          render={({ form }) => (
                            <Row>
                              <Col sm="6" className="broker-builder__phone-radio">
                                <div className="broker-builder__short-div">
                                  <TextField
                                    name="officePhone"
                                    key="broker.officePhone-key"
                                    label={S.BRO_PHONE_TYPE_OFFICE}
                                    type="tel"
                                    parse={formatString(officePhoneMask.parse)}
                                  />
                                </div>
                                <div className="broker-builder__radio_wrap">
                                  <label>
                                    <Field
                                      type="radio"
                                      name="primaryPhone"
                                      value={S.BRO_PHONE_TYPE_OFFICE}
                                      component="input"
                                    />{" "}
                                    {this.getPrimaryPhoneLabel(form, S.BRO_PHONE_TYPE_OFFICE)}
                                  </label>
                                </div>
                              </Col>
                              <Col sm="3" className="broker-builder__phone-radio">
                                <div className="broker-builder__short-div">
                                  <TextField
                                    name="cellPhone"
                                    key="broker.cellPhone-key"
                                    label={S.BRO_PHONE_TYPE_CELL}
                                    type="tel"
                                    parse={formatString(phoneMask.parse)}
                                  />
                                </div>
                                <div className="broker-builder__radio_wrap">
                                  <label>
                                    <Field
                                      type="radio"
                                      name="primaryPhone"
                                      value={S.BRO_PHONE_TYPE_CELL}
                                      component="input"
                                    />{" "}
                                    {this.getPrimaryPhoneLabel(form, S.BRO_PHONE_TYPE_CELL)}
                                  </label>
                                </div>
                              </Col>
                              <Col sm="3">
                                <TextField
                                  name="faxPhone"
                                  key="broker.faxPhone-key"
                                  label={S.BRO_PHONE_TYPE_FAX}
                                  type="tel"
                                  parse={formatString(phoneMask.parse)}
                                />
                              </Col>
                            </Row>
                          )}
                        />
                        <Row>
                          <Col>
                            <h1 className="heading3">{S.BRO_EMAIL_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              name="primaryEmail"
                              key="broker.primaryEmail-key"
                              label={`${S.BRO_EMAIL_TYPE_PRIMARY} Email`}
                              type="email"
                            />
                            <OnChange name="primaryEmail">
                              {value => {
                                var primaryEmail = value;
                                if (primaryEmail && primaryEmail.length > 4) {
                                  var re = /\S+@\S+\.\S+/;
                                  var isValid = re.test(primaryEmail);
                                  if (isValid) {
                                    this.props.fetchBrokerByPrimaryEmail(
                                      this.brokerID,
                                      primaryEmail,
                                    );
                                  }
                                }
                              }}
                            </OnChange>
                          </Col>
                          <Col sm="6">
                            <TextField
                              name="alternateEmail"
                              key="broker.alternateEmail-key"
                              label={`${S.BRO_EMAIL_TYPE_ALT} Email`}
                              type="email multiple"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <ExistentBrokerNav />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              name="ccEmail"
                              key="broker.ccEmail-key"
                              label={`${S.BRO_EMAIL_TYPE_CC} Email`}
                              type="email multiple"
                            />
                          </Col>
                          <Col sm="6">
                            <TextField
                              name="requestorEmail"
                              key="broker.requestorEmail-key"
                              label={`${S.BRO_EMAIL_TYPE_REQUESTER} Email`}
                              type="email"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1 className="heading3">{S.BRO_ADDRESS_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <TextField
                              name="address.streetAddress"
                              key="broker.streetAddress-key"
                              label={S.BRO_STREET_ADDRESS_LABEL}
                            />
                          </Col>
                          <Col sm="3">
                            <TextField
                              name="address.city"
                              key="broker.city-key"
                              label={S.BRO_CITY_LABEL}
                            />
                          </Col>
                          <Col sm="3">
                            <Container className="bootsrap-container-override">
                              <Row>
                                <Col>
                                  <SingleSelectField
                                    name="address.stateID"
                                    key="broker.state-key"
                                    label={S.BRO_STATE_LABEL}
                                    options={stateList}
                                  />
                                </Col>
                                <Col>
                                  <TextField
                                    name="address.zip"
                                    key="broker.zip-key"
                                    label={S.BRO_ZIP_LABEL}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h1 className="heading3">{S.BRO_NOTES_TITLE}</h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <TextField
                              name="notes"
                              key="broker.notes-key"
                              label={S.BRO_NOTES_LABEL}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ToggleField
                              key={"sms-toggle-field"}
                              name={"isSMSTextEnabled"}
                              label={S.UDM_SEND_SMS}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <img src={logoBrokerUrl} className="logoPreview" />
                            <br />
                            <br />
                            <br />
                            <Fragment>
                              <input
                                color="primary"
                                accept="image/*"
                                type="file"
                                onChange={this.onBrokerFileSelectionChange}
                                id="icon-button-file"
                                style={{ display: "none" }}
                              />
                              &nbsp;
                              <label htmlFor="icon-button-file">
                                <Button
                                  variant="contained"
                                  component="span"
                                  className="button5 button__orange"
                                  size="large">
                                  Broker Logo&nbsp;
                                  <Icon>backup</Icon>
                                </Button>
                              </label>
                              &nbsp;&nbsp;
                              <label>
                                <Button
                                  variant="contained"
                                  component="span"
                                  className="button5 button__orange"
                                  onClick={this.onBrokerFileDelete}
                                  size="large">
                                  Delete Logo&nbsp;
                                  <Icon>delete</Icon>
                                </Button>
                              </label>
                            </Fragment>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="broker-builder__top-bar">
                              <div className="buttons">
                                <ul>
                                  <li>
                                    <button
                                      className="button5 button__orange"
                                      type="submit"
                                      disabled={submitting || existentBrokerIdByEmail}>
                                      {submitting || existentBrokerIdByEmail ? (
                                        <Spinner color="light" />
                                      ) : (
                                        S.BRO_SUBMIT_BUTTON
                                      )}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                    <aside>
                      <div className="broker-builder__card-wrap">
                        <div className="heading3 broker-builder__card-title">
                          {S.BRO_CARD_TITLE}
                        </div>
                        <Container>
                          <Row className="card-row">
                            <Col>{S.BRO_CARD_CARRIER}</Col>
                            <Col />
                            <Col>{S.BRO_CARD_YTD_TOTAL}</Col>
                          </Row>
                          {brokerProductionNodes}
                          {totalPremium > 0 && <Row className="card-separator" />}
                          {totalPremium > 0 && (
                            <Row className="card-row card-totals">
                              <Col>{S.BRO_CARD_TOTAL_LABEL}</Col>
                              <Col />
                              <Col>{formatUSD(totalPremium)}</Col>
                            </Row>
                          )}
                        </Container>
                      </div>
                      <div className="broker-builder__card-wrap">
                        <BrokerQuotesWidjet />
                      </div>
                      <div className="broker-builder__card-wrap">
                        <BrokerSecureDocumentsWidjet />
                      </div>
                    </aside>
                  </div>
                  <FormSpy
                    subscription={{ values: true, valid: true }}
                    onChange={state => {
                      const { values, valid } = state;

                      this.defaultBroker = values;
                    }}
                  />
                </form>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  brokers,
  brokerImages,
  brokerBuilder: {
    loading,
    companyAffiliate,
    existentBrokerIdByEmail,
    isAfiliateRequestInProgress,
    ...dropdownOptions
  },
}: IRootState) => ({
  brokers,
  brokerImages,
  companyAffiliate,
  isAfiliateRequestInProgress,
  dropdownOptions,
  existentBrokerIdByEmail,
  loading,
});

const mapDispatchToProps = {
  createBroker,
  fetchBrokerOptions,
  fetchBroker,
  updateBroker,
  uploadBrokerLogo,
  saveBrokerLogo,
  deleteBrokerLogo,
  fetchCompanyAffiliate,
  fetchBrokerByPrimaryEmail,
  hideErrorToastBroker,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrokerBuilder);
