export interface IBroker {
  brokerID: 0;
  companyID: 0;
  affiliateID: 0;
  brokerName: string;
  preferredName: string;
  primaryPhone: string;
  primaryEmail: string;
  companyName: string;
  affiliateName: string;
  externalID: number;
  isSMSTextEnabled: boolean;
  fromBrokerQuoteEngine?: boolean;
  brokerProduction: [
    {
      carrierType: string;
      premiumTotal: number;
    },
  ];
  brokerLogo: string;
  logo: string | ArrayBuffer;
}

export interface IBrokerImage {
  brokerID: 0;
  formData: FormData;
  imagePreview: string | ArrayBuffer;
}

export const defaultBroker: Readonly<IBroker> = {
  brokerID: null,
  companyID: null,
  affiliateID: null,
  brokerName: "",
  preferredName: "",
  primaryPhone: "",
  primaryEmail: "",
  companyName: "",
  affiliateName: "",
  externalID: null,
  isSMSTextEnabled: true,
  fromBrokerQuoteEngine: null,
  brokerProduction: [
    {
      carrierType: undefined,
      premiumTotal: undefined,
    },
  ],
  brokerLogo: null,
  logo: null,
};
