import moment from "moment";
import React, { useEffect, useState } from "react";
import type { RouteComponentProps } from "react-router-dom";
import Toast from "src/components/Toast/Toast";
import * as S from "src/constants/StringConstants";
import CampaignBuilder from "./CampaignBuilder";
import CampaignFileUploader from "./CampaignFileUploader/CampaignFileUploader";

import { Spinner } from "reactstrap";
import { deleteCampaign, fetchDraftCampaign, uploadSpreadsheet } from "./api";
import "./CampaignBuilder.scss";
import { unsurroundWithBrackets } from "./utils";

interface Props extends RouteComponentProps<{ id: string }> {}

const initialState = {
  campaignId: null,
  options: {
    time: "11:00 am",
    firstDeliveryDate: moment().add(1, "day").format(moment.HTML5_FMT.DATE),
    expirationDate: moment().add(2, "month").format(moment.HTML5_FMT.DATE),
    emailFooter:
      "DIS acts in the capacity of a Third-Party Administrator on behalf of the insurance agent and not in the capacity of an insurance agent.",
  },
  templateSteps: [],
  deletedSteps: [],
};

const CampaignContainer: React.FC<Props> = props => {
  const id = new URLSearchParams(props.location.search).get("id");

  const [fileData, setFileData] = useState<any | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [title, setTitle] = useState<string | null>(null);

  const loadExistingCampaignIfNeeded = async () => {
    if (id) {
      setLoading(true);
      const { data } = await fetchDraftCampaign(id);
      setLoading(false);
      const { steps, carrierId, campaignName, emailFooter, deliveryDate, deliveryTime, expirationDate, saveAsDraft } =
        data;

      const templateSteps = steps.map(
        ({
          stepId,
          kind,
          subject,
          content,
          intervalDays,
          stepOrder,
          to,
          from,
          cc,
          replyTo,
          bcc,
          messageQueues,
          status,
        }) => {
          return {
            stepId,
            kind,
            subject,
            content,
            intervalDays,
            stepOrder,
            to: unsurroundWithBrackets(to),
            from: unsurroundWithBrackets(from),
            cc: unsurroundWithBrackets(cc),
            replyTo: unsurroundWithBrackets(replyTo),
            bcc: unsurroundWithBrackets(bcc),
            messageQueues,
            status,
          };
        },
      );

      const options = {
        firstDeliveryDate: deliveryDate,
        time: deliveryTime,
        emailFooter,
        carrierId,
        expirationDate,
      };

      const rowData = JSON.parse(data.rowData);
      const { rows, columnNames } = JSON.parse(data.rawFileData);
      const mapTo = JSON.parse(data.fieldMapping);

      setTitle(campaignName);
      setFileData({
        ...initialState,
        campaignId: id,
        fileName: campaignName,
        saveAsDraft,
        rows,
        columnNames,
        mapTo,
        templateSteps,
        options,
        rowData,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await loadExistingCampaignIfNeeded();
      } catch (e) {
        alert(S.CAMP_ERROR_LOADING);
        props.history.push("/campaigns/all");
      }
    })();
  }, []);

  const uploadFile = async (file, newFile = true, existingValues: Record<string, any> = {}) => {
    setUploading(true);
    try {
      const response = await uploadSpreadsheet(file, setUploadPercentage);
      setFileData(
        newFile
          ? {
              ...response,
              ...initialState,
            }
          : {
              ...existingValues,
              ...response,
            },
      );
    } catch (err) {
      const errorMessages = err?.message?.toString() || S.UDM_ERROR_ON_UPLOAD;
      setUploadError(errorMessages);
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteCampaign = async () => {
    if (confirm(S.CAMP_CONFIRM_DELETE_CAMPAIGN)) {
      if (id) {
        await deleteCampaign(id);
        props.history.push("/campaigns/all");
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Toast message={uploadError} onClose={() => setUploadError(null)} open={uploadError !== null} />
      <Toast message={S.UPLOADING(uploadPercentage)} onClose={() => {}} open={uploading} />

      <h1 className="heading1 grey--light mb-5">{title || S.NEW_CAMPAIGN_TITLE}</h1>
      <div>
        {fileData === null ? (
          <CampaignFileUploader
            onDrop={async files => {
              if (files?.length === 1) {
                await uploadFile(files[0]);
              }
            }}
          />
        ) : (
          <CampaignBuilder
            campaignId={id}
            initialValue={fileData}
            onNameChange={setTitle}
            onReplaceFile={async (files, values) => {
              await uploadFile(files[0], false, values);
            }}
            onDeleteCampaign={handleDeleteCampaign}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignContainer;
