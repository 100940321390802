import { get, parseInt } from "lodash";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";

import { RouteComponentProps } from "react-router-dom";
import ManageProductItemsModal from "src/components/Modals/ManageProductItemsModal/ManageProductItemsModal";
import { IRootState } from "src/store/reducers";
import { fetchProduct } from "src/store/reducers/productsV2";
import CheckboxField from "../../components/Form/CheckboxField/CheckboxField";
import SingleSelectField from "../../components/Form/SingleSelectField/SingleSelectField";
import Toast from "../../components/Toast/Toast";
import * as S from "../../constants/StringConstants";
import {
  createProduct,
  deleteOption,
  fetchProductBuilderOptions,
  toggleProcessInProgressToast,
  toggleProcessStartFailedToast,
  toggleProcessStartSuccessToast,
  updateOption,
} from "../../store/reducers/productBuilder";
import "./ProductBuilder.scss";

export interface IProductBuilderProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

/**
 * [ optionKey, fieldName, fieldLabel]
 * optionKey, maps to redux dropdown options
 * fieldName, name for final submission
 */

const formFieldStructure = [
  ["occupationClassTypeList", "occClassTypeIDs", S.PB_OCCUPATION_CLASS_TYPE, "occ-classes"],
  ["renewabilityTypeList", "renewabilityTypeIDs", S.PB_RENEWABILITY_TYPE, "renewabilities"],
  ["bpTypeList", "bpTypeIDs", S.PB_BP_TYPE, "bps"],
  ["epTypeList", "epTypeIDs", S.PB_EP_TYPE, "eps"],
  ["ownOccTypeList", "ownOccTypeIDs", S.PB_OWN_OCC_TYPE, "own-occs"],
  ["residualTypeList", "residualTypeIDs", S.PB_RESIDUAL_TYPE, "residuals"],
  [
    "minimumResidualBenefitPayableTypeList",
    "minimumResidualBenefitPayableTypeIDs",
    S.PB_MINIMUM_RESIDUAL_BENEFIT_TYPE,
    "minimum-residual-benefit-payables",
  ],
  [
    "futureInsurabilityOptionsTypeList",
    "futureInsurabilityOptionsTypeIDs",
    S.PB_FUTURE_INSURABILITY_OPTIONS_TYPE,
    "future-insurability-options",
  ],
  ["airTypeList", "airTypeIDs", S.PB_AIR_TYPE, "airs"],
  ["colaTypeList", "colaTypeIDs", S.PB_COLA_TYPE, "colas"],
  [
    "mentalNervousLimitationTypeList",
    "mentalNervousLimitationTypeIDs",
    S.PB_MENTAL_NERVOUS_LIMITATION_TYPE,
    "mental-nervous-limitation",
  ],
  [
    "catastrophicBenefitRiderTypeList",
    "catastrophicBenefitRiderTypeIDs",
    S.PB_CATASTROPHIC_BENEFIT_TYPE,
    "catastrophic-benefit-riders",
  ],
  ["uniqueProvisionsTypeList", "uniqueProvisionsTypeIDs", S.PB_UNIQUE_PROVISIONS_TYPE, "unique-provisions"],
];

const addDefaultOption = (label, options) => [{ key: label, value: "", disabled: false }, ...options];

interface IProductBuilderState {
  modalOpen: boolean;
  selectedProductName?: string;
  selectEntityName?: string;
  selectedOptions: Array<{ key: string; value: number }>;
}

export class ProductBuilder extends React.Component<IProductBuilderProps, IProductBuilderState> {
  state: IProductBuilderState = {
    modalOpen: false,
    selectedProductName: "",
    selectEntityName: "",
    selectedOptions: [],
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await this.props.fetchProductBuilderOptions();
    const id = this.id;
    if (id) {
      await this.props.fetchProduct(id.toString());
    }
  }

  get id() {
    const id = this.props.match.params.id;
    return id != "new" ? id : null;
  }

  defaultProduct = undefined;
  get product() {
    if (this.id) {
      return this.props.products.map[this.id];
    } else {
      if (!this.defaultProduct) {
        this.defaultProduct = {};
      }

      return this.defaultProduct;
    }
  }

  onSubmit = async values => {
    // these need to be sent as arrays
    values["carrierTypeIDs"] = [values["carrierTypeID"]];
    values["designTypeIDs"] = [values["designTypeID"]];
    try {
      await this.props.createProduct(values);
      this.props.history.push("/products/all");
    } catch (e) {}
  };

  onValidate = values => {
    const errors = {};

    formFieldStructure.map(field => {
      const paramName = field[1];
      if (!values[paramName] || values[paramName].length == 0) {
        errors[paramName] = S.FORM_FIELD_REQUIRED;
      }
    });
    if (!values["productTypeID"]) {
      errors["productTypeID"] = S.FORM_FIELD_REQUIRED;
    }
    if (!values["carrierTypeID"]) {
      errors["carrierTypeID"] = S.FORM_FIELD_REQUIRED;
    }
    if (!values["designTypeID"]) {
      errors["designTypeID"] = S.FORM_FIELD_REQUIRED;
    }
    return errors;
  };

  getClassName(errors: any, name: string, dirtyFields: any) {
    var errorText = get(errors, name);
    var isDirtyField = get(dirtyFields, name + "[]");
    if (errorText && isDirtyField) {
      return "error";
    }

    return "";
  }

  productToFormValues = (product: any) => {
    return {
      ...product,
    };
  };

  handleSaveItems = async (updatedItems: Array<{ key: string; value?: number }>) => {
    const existingItems = [...this.state.selectedOptions];
    const diff = updatedItems.reduce(
      (acc, item, index) => {
        const oldIndex = existingItems.findIndex(o => o.value === item.value);

        if (oldIndex === -1) {
          // skip empty items
          if (item.key.length > 0) {
            acc.added.push(item);
          }
          return acc;
        }

        const old = existingItems.splice(oldIndex, 1)[0];
        if (old.key !== item.key) {
          acc.edited.push(item);
        }
        return acc;
      },
      { added: [], edited: [] },
    );

    const { selectEntityName } = this.state;
    const ops = [
      ...diff.added.map(item => updateOption(selectEntityName, item)),
      ...diff.edited.map(item => updateOption(selectEntityName, item)),
      ...existingItems.map(item => deleteOption(selectEntityName, item.value)),
    ];
    try {
      if (ops.length > 0) {
        await Promise.all(ops);
        await this.props.fetchProductBuilderOptions();
      }
    } catch (e) {
      alert(e);
    }
    this.setState({
      modalOpen: false,
    });
  };

  handleManageClick = (productName: string, optionsKey: string, entityName: string) => {
    const selectedOptions = [...(this.props.dropdownOptions[optionsKey] || [])];
    this.setState({
      modalOpen: true,
      selectEntityName: entityName,
      selectedProductName: productName,
      selectedOptions,
    });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { dropdownOptions, loading, createProcessFailed, products, saving } = this.props;
    const productLoading = products.loading;

    const productOptions = addDefaultOption(S.PB_DEFAULT_PRODUCT_TYPE, dropdownOptions.productTypeList);
    const carrierOptions = addDefaultOption(S.PB_DEFAULT_CARRIER_TYPE, dropdownOptions.carrierTypeList);
    const designOptions = addDefaultOption(S.PB_DEFAULT_DESIGN_TYPE, dropdownOptions.designTypeList);

    const defaultValues = {};
    const initialValues = this.product ? this.productToFormValues(this.product) : defaultValues;

    return (
      <div>
        <Toast
          message={S.PB_PRODUCTS_LOADED}
          onClose={() => {
            this.props.toggleProcessInProgressToast(false);
          }}
          open={loading || productLoading}
        />
        <Toast message={S.PB_PRODUCTS_SAVING} onClose={() => {}} open={saving} />
        <Toast
          message={S.PB_PRODUCT_CREATION_PROCESS_BUSY}
          onClose={() => {
            this.props.toggleProcessStartFailedToast(false);
          }}
          open={createProcessFailed}
        />
        <div>
          <h1 className="heading1 grey--light affiliate-builder__title">{S.PROD_DETAIL_PAGE_TITLE}</h1>
        </div>
        <FinalForm
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validate={this.onValidate}
          render={({ handleSubmit, submitting, invalid, errors, dirtyFields, form, values }) => {
            return (
              <form
                className="product-builder__wrap"
                onSubmit={async event => {
                  await handleSubmit(event);
                  form.reset();
                }}>
                <Container>
                  <Row>
                    <Col sm="5">
                      <h1 className="heading3">{S.PB_PRODUCT_TYPE}</h1>
                      <SingleSelectField
                        key={"product-type-field"}
                        name={"productTypeID"}
                        options={productOptions}
                        parse={value => parseInt(value)}
                        disabled={this.id}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="5">
                      <h1 className="heading3">{S.PB_CARRIER_TYPE}</h1>
                      <SingleSelectField
                        key={"carrier-type-field"}
                        name={"carrierTypeID"}
                        options={carrierOptions}
                        parse={value => parseInt(value)}
                        disabled={this.id}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="5">
                      <h1 className="heading3">{S.PB_DESIGN_TYPE}</h1>
                      <SingleSelectField
                        key={"design-type-field"}
                        name={"designTypeID"}
                        options={designOptions}
                        parse={value => parseInt(value)}
                        disabled={this.id}
                      />
                    </Col>
                  </Row>
                  {formFieldStructure.map(([optionsKey, name, label, entityName]) => (
                    <Row key={name}>
                      <Col>
                        <Container>
                          <Row className="align-items-center">
                            <Col className={this.getClassName(errors, name, dirtyFields)}>
                              <h1 className="heading3">
                                {label}
                                <Button
                                  color="secondary"
                                  onClick={() => this.handleManageClick(label, optionsKey, entityName)}>
                                  {S.PB_MANAGE_BUTTON}
                                </Button>
                              </h1>
                            </Col>
                          </Row>
                          <Row>
                            {dropdownOptions[optionsKey]
                              .sort((a, b) => a.key.localeCompare(b.key))
                              .map(option => {
                                return (
                                  <Col key={`${name}_${option.key}_${option.value}`} sm="3">
                                    <CheckboxField label={option.key} name={`${name}[]`} value={option.value} />
                                  </Col>
                                );
                              })}
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  ))}
                  <div className="product-builder__spacer-40" />
                  <div className="product-builder__button-wrap">
                    <button className="button__orange" type="submit" disabled={submitting}>
                      {S.PB_SUBMIT_BUTTON}
                    </button>
                  </div>
                </Container>
              </form>
            );
          }}
        />

        {/* Modal Component for managing items*/}
        <ManageProductItemsModal
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
          productName={this.state.selectedProductName}
          items={this.state.selectedOptions}
          onSave={this.handleSaveItems}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  productsV2: products,
  productBuilder: { loading, createProcessFailed, saving, ...dropdownOptions },
}: IRootState) => ({
  loading,
  saving,
  createProcessFailed,
  dropdownOptions,
  products,
});

const mapDispatchToProps = {
  fetchProductBuilderOptions,
  fetchProduct,
  createProduct,
  toggleProcessInProgressToast,
  toggleProcessStartSuccessToast,
  toggleProcessStartFailedToast,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductBuilder);
